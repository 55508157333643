
import React from "react"
import Layout from "../components/layout"
import SEO from '../components/seo';

export default () => (
  <Layout>
    <SEO title="Gold Coast - Australia | Kiwi & Cacahuate" description="A family holiday to the gold coast to enjoy the sun and beaches." url="https://kiwicacahuate.com/gold-coast/" image="https://kiwicacahuate.com/gold-coast/gold-coast.jpg" imageWidth="3000" imageHeight="2131" />


    <div className="">
      <div className="story">
        <div className="photoset-section">
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="003" width="2800" height="2131">
                <source srcSet="/gold-coast/thumbnail/003.webp, /gold-coast/thumbnail/003-2x.webp 2x" />
                <source srcSet="/gold-coast/thumbnail/003.jpg, /gold-coast/thumbnail/003-2x.jpg 2x" />
                <img src="/gold-coast/thumbnail/003.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="002" width="2800" height="2001">
                <source srcSet="/gold-coast/thumbnail/002.webp, /gold-coast/thumbnail/002-2x.webp 2x" />
                <source srcSet="/gold-coast/thumbnail/002.jpg, /gold-coast/thumbnail/002-2x.jpg 2x" />
                <img src="/gold-coast/thumbnail/002.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="004" width="2800" height="2001">
                <source srcSet="/gold-coast/thumbnail/004.webp, /gold-coast/thumbnail/004-2x.webp 2x" />
                <source srcSet="/gold-coast/thumbnail/004.jpg, /gold-coast/thumbnail/004-2x.jpg 2x" />
                <img src="/gold-coast/thumbnail/004.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="005" width="2800" height="1722">
                <source srcSet="/gold-coast/thumbnail/005.webp, /gold-coast/thumbnail/005-2x.webp 2x" />
                <source srcSet="/gold-coast/thumbnail/005.jpg, /gold-coast/thumbnail/005-2x.jpg 2x" />
                <img src="/gold-coast/thumbnail/005.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="006" width="2800" height="2001">
                <source srcSet="/gold-coast/thumbnail/006.webp, /gold-coast/thumbnail/006-2x.webp 2x" />
                <source srcSet="/gold-coast/thumbnail/006.jpg, /gold-coast/thumbnail/006-2x.jpg 2x" />
                <img src="/gold-coast/thumbnail/006.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="007" width="2800" height="2001">
                <source srcSet="/gold-coast/thumbnail/007.webp, /gold-coast/thumbnail/007-2x.webp 2x" />
                <source srcSet="/gold-coast/thumbnail/007.jpg, /gold-coast/thumbnail/007-2x.jpg 2x" />
                <img src="/gold-coast/thumbnail/007.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="008" width="2800" height="2001">
                <source srcSet="/gold-coast/thumbnail/008.webp, /gold-coast/thumbnail/008-2x.webp 2x" />
                <source srcSet="/gold-coast/thumbnail/008.jpg, /gold-coast/thumbnail/008-2x.jpg 2x" />
                <img src="/gold-coast/thumbnail/008.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="009" width="2800" height="2001">
                <source srcSet="/gold-coast/thumbnail/009.webp, /gold-coast/thumbnail/009-2x.webp 2x" />
                <source srcSet="/gold-coast/thumbnail/009.jpg, /gold-coast/thumbnail/009-2x.jpg 2x" />
                <img src="/gold-coast/thumbnail/009.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="010" width="2800" height="2041">
                <source srcSet="/gold-coast/thumbnail/010.webp, /gold-coast/thumbnail/010-2x.webp 2x" />
                <source srcSet="/gold-coast/thumbnail/010.jpg, /gold-coast/thumbnail/010-2x.jpg 2x" />
                <img src="/gold-coast/thumbnail/010.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
        </div>
      </div>
    </div>

  </Layout>
)

